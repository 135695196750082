import React, { Component } from 'react'
import LeftNav from "../components/LeftNav"

export default class Ricercasemica extends Component {
    render() {
        return(
            <div className="pageContainer">
                <LeftNav/>
                <div className="mainContent blackBg" style={{overflowY: "auto"}}>
                    <div>
                        <div style={{padding: "50px 50px 0 50px"}}>
                            <p className="collectionTitle">
                                Ricerc(a)semica (prequel)
                            </p>
                            <br/><br/>
                            <p>
                                The meaning is the research.<br/>
                                Meaning intrinsic of the things<br/>
                                no<br/>
                                meaning intrinsic of the language<br/>
                                part of language<br/>
                                dependent from language<br/>
                                property of language.<br/>
                                Communication as vector of meaning<br/>
                                it carries the meaning<br/>
                                but also<br/>
                                the meaning cannot be carried<br/>
                                without it.<br/>
                                Individual meaning that becomes shared,<br/>
                                Carattere, Linea, Testo, Compressione and Evoluzione.<br/>
                                Researches at every level to reach another,<br/>
                                a ring<br/>
                                or better<br/>
                                a spiral.<br/>
                                Return with addition to restart,<br/>
                                again,<br/>
                                in other researches<br/>
                                part of the same research<br/>
                                the only possibility.
                            </p>
                            <hr/>
                            <p className="textJustified">
                                Ricerc(a)semica is the result of a first immersion in the problem of meaning.<br/>
                                Far from offering answers, it's a phase of suggestions and proposals: visual explorations of an
                                unreadable langauge, rythmical and melodic result of rules defined through code.
                                A language created by a programming language to explore the limits of common language.
                                A way to give back some control and create a new equilibrium of meaning between writer and reader.
                            </p>
                            <p className="textJustified">
                                It starts from nothing. <i>Let us then suppose the mind to have no ideas in it, to be like white paper with nothing written on it.</i> And then perceptions arrive. Each a dot that drags itself until it forms the core element: the character. Origin of language, divisibile yet essential atom. Cells that grow with time, characters turn concrete and become our alphabet, always more elaborate, always more complex, always more complete.
                            </p>
                            <p className="textJustified">
                                <i>I never can catch myself at any time without a perception, and never can observe any thing but the perception.</i> And perceptions are not disconnected as we may think. Our minds connect them with rules, proximity, causality: the points become a line. A line that connects the beginning and the end, that traverses every moment and varies with the variations of the external and the internal. Maybe influenced more by the context than the content. It's a story, unique: indivisible and different from every other.
                            </p>
                            <p className="textJustified">
                                Until we reach structure: the text. Self-determination of language in a system that describes itself through a labyrinth of variable, ephemeral, ambiguous meanings. Every character that connects in words assumes a role that determines itself and its surroundings up to the entire system. And that's how systems and structure multiply, through small changes that transform mercury in gold.
                            </p>
                            <p className="textJustified">
                                But the structures are not eternal. With the passage of time they crumble or solidify, both degenerations in opposite extremes that are the result of the apparition of a new element in the system: silence. The difference is too vast to be integrated, from pure creation through the addition of an element of absorption we obtain decay. Compression.
                            </p>
                            <p className="textJustified">
                                And the cycle is now complete. Birth, growth, decay, and death. Language rises and breathes. Grows and evolves, compresses itself and dies. But the language is not unique. It's part of an even wider system that includes all its similars, and the not so similar. They contaminate each other and procreate, live lives like ours: some meaningless and some so dense that it's hard to see the difference.
                            </p>
                            <hr/>
                            <p className="textJustified">
                                The twenty artworks that follow are unique iterations of algorithms written by me, drawn by my pen plotter with a Schneider Topball 847 on linen paper in A3 format (29,7 x 42 cm), signed on the back and manually framed with natural wood.
                                For more informations or to purchase <a href="mailto:encapsuled.art@gmail.com" target="_blank" rel="noopener noreferrer"> encapsuled.art@gmail.com</a>
                            </p>
                            <br/><br/>
                            <p className="collectionTitle">
                                Carattere
                            </p>
                            <img
                                src='/images/ricercasemica/char1.svg'
                                alt='char_1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/char2.svg'
                                alt='char_2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/char3.svg'
                                alt='char_3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/char4.svg'
                                alt='char_4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Linea
                            </p>
                            <img
                                src='/images/ricercasemica/lines1.svg'
                                alt='lines1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/lines2.svg'
                                alt='lines2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/lines3.svg'
                                alt='lines3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/lines4.svg'
                                alt='lines4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Testo
                            </p>
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text1.svg'
                                alt='text1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text2.svg'
                                alt='text2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text3.svg'
                                alt='text3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/text4.svg'
                                alt='text4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Compressione
                            </p>
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress1.svg'
                                alt='compress1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress2.svg'
                                alt='compress2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress3.svg'
                                alt='compress3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/compress4.svg'
                                alt='compress4' className="pieceImgWide"
                            />
                            <br/><br/>
                            <p className="collectionTitle">
                                Evoluzione
                            </p>
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo1.svg'
                                alt='evo1' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo2.svg'
                                alt='evo2' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo3.svg'
                                alt='evo3' className="pieceImgWide"
                            />
                            <br/><br/>
                            <img
                                src='/images/ricercasemica/evo4.svg'
                                alt='evo4' className="pieceImgWide"
                            />
                            <br/><br/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}